class BuchhandlungBilder extends HTMLElement {
    
  connectedCallback() {
    this.globaleVariablenDeklarieren();
    this.bilderSlider.addEventListener('scroll', (event) => { this.scrollHandler(event) }, { once: true });
    this.initKontrollelemente();
    this.initObserver();
    this.aktivesSliderElement = 1;
  }

  globaleVariablenDeklarieren() {
      this.bilderSlider = this.querySelector('.slider-list');
      this.sliderBox = this.querySelector('.slider-box');
      this.erstesSliderElement = this.querySelector('.slider-item:first-of-type');
      this.letztesSliderElement = this.querySelector('.slider-item:last-of-type');
      this.prev = this.querySelector('[interaction="vorherige-bilder-zeigen"]');
      this.next = this.querySelector('[interaction="naechste-bilder-zeigen"]');
  }

  scrollHandler(event) {
      if (event.target.scrollLeft > 0) {
          this.prev.setAttribute('data-status', 'aktiv');
      }
  }

  initKontrollelemente() {
      this.prev.addEventListener('click', event => this.prevKlickHandler(event));
      this.next.addEventListener('click', event => this.nextKlickHandler(event));
  }

  prevKlickHandler(event) {
      this.aktivesSliderElement -= 1;
      const scrollBreite = this.getScrollBreite();
      this.zurPositionScrollen(this.bilderSlider, { 
        left: this.bilderSlider.scrollLeft - scrollBreite
      });
  }

  nextKlickHandler(event) {
      this.aktivesSliderElement += 1;
      const scrollBreite = this.getScrollBreite();
      this.zurPositionScrollen(this.bilderSlider, { 
        left: this.bilderSlider.scrollLeft + scrollBreite
      });
    }
    
    getScrollBreite() {
      const element = this.querySelector(`.slider-item:nth-child(${this.aktivesSliderElement})`)
      if(!element) { return; }
      const elementStyle = this.erstesSliderElement.currentStyle || window.getComputedStyle(this.erstesSliderElement);
      const padding = parseFloat(elementStyle.paddingLeft) + parseFloat(elementStyle.paddingRight);
      const elementBoundingLeft = element.getBoundingClientRect().left;
      return Math.abs(elementBoundingLeft - padding);
  }

  zurPositionScrollen(element, options) {
      element.scrollTo({
          top: options.top || 0,
          left: options.left || 0,
          behavior: 'smooth'
      });
  }

  initObserver() {
      this.options = {
          root: this.sliderBox,
          threshold: [0.95, 0.96, 0.97, 0.98, 0.99, 1.0]
      };
      this.observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => this.intersectionHandler(entry));
      }, this.options);
      this.querySelectorAll('.slider-item:first-of-type, .slider-item:last-of-type').forEach(element => this.observer.observe(element));
  }

  intersectionHandler(entry) {
      if (entry.target === this.erstesSliderElement && this.statusIstNichtInitial(this.prev)) {
          this.prev.setAttribute('data-status', entry.intersectionRatio === 1 ? 'inaktiv' : 'aktiv');
      }
      if (entry.target === this.letztesSliderElement) {
          this.next.setAttribute('data-status', entry.intersectionRatio === 1 ? 'inaktiv' : 'aktiv');
      }
  }

  statusIstNichtInitial(element) {
      return element.dataset['status'] !== undefined;
  }

}

if (!customElements.get("buchhandlung-bilder")) {
  customElements.define("buchhandlung-bilder", BuchhandlungBilder);
}
